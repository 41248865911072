import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './shared/routes/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/template-tabs/template-tabs.module').then(module => module.TabsTemplateModule),
  },
  {
    path: 'asset-creation',
    loadChildren: () => import('./modules/asset-creation/asset-creation.module').then(module => module.AssetCreationModule),
  },
  {
    path: '**',
    component: EmptyRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: APP_BASE_HREF, useValue: '/field-templates' }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
