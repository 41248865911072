export const environment_common = {
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZHBpZXRyb2NhcmxvIiwiYSI6ImNram9tOGFuMTBvb3oyeXFsdW5uYmJjNGQifQ._zE6Mub0-Vpl7ggMj8xSUQ'
  },
  token: 'NA.K0TK4eBHvuGArGS5zhRH-nzKy0HqXDz4H0jejOvFxXE457d5CL8wWJ4oFRez',
  AAToken: 'X-AA-ApiKey -f_hj875cgiEbRqGvh3P2Utlj81xH86sc29eVnhz0',
  AAURL: 'https://api.altitudeangel.com',
  MeteomaticUser: 'aerodynegroup_dev',
  Meteomatickey: 'v9r5StJB92',
  Airmap: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVkZW50aWFsX2lkIjoiY3JlZGVudGlhbHw1Tzc0azBCQzlPa1FOYXU4NmJBQjZjdmFtTjRuIiwiYXBwbGljYXRpb25faWQiOiJhcHBsaWNhdGlvbnw3T0dHT2d5dUxXYURaNVR2MDc0WnlmcGt3NFAyIiwib3JnYW5pemF0aW9uX2lkIjoiZGV2ZWxvcGVyfFBLeEdBcW50elhienhLU1B4ZUtMT3M4ZHdYbjUiLCJpYXQiOjE2ODQ4OTg5MDF9.mV-kcug1kr-JAUnNUwVkRU-6gqXzhNEE4uNFHSob880',
  AUTH_APP_SITE_KEY : '0x4AAAAAAATT95YFFr2VYgmK'
}